var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(
        _vm.$store.getters["user/notifications"],
        function (notification, i) {
          return _c(
            "b-toast",
            {
              key: i,
              attrs: {
                title: _vm.levelToTitle(notification.level),
                variant: _vm.levelToVariant(notification.level),
                "toast-class": "toast-notification",
                "no-close-button": "",
                "no-auto-hide": "",
                solid: "",
                static: "",
                visible: "",
                "is-status": "",
              },
            },
            [
              _c(
                "b-row",
                { attrs: { "no-gutters": "", "align-v": "center" } },
                [
                  _c("b-col", [
                    _vm._v(" " + _vm._s(notification.message) + " "),
                  ]),
                  _c(
                    "b-col",
                    { attrs: { cols: "auto", "align-h": "end" } },
                    [
                      _c(
                        "b-btn",
                        {
                          attrs: {
                            size: "sm",
                            variant: _vm.levelToVariant(notification.level),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.dismissNotification(i)
                            },
                          },
                        },
                        [_vm._v(" Dismiss ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }
      ),
      _vm.noNotifications
        ? _c("p", { staticClass: "lead text-muted text-center" }, [
            _vm._v(" No Notifications to Show "),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }